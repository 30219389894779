html, body {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #0D1117;
  color: #C9D1D9;
  overflow: hidden; 
}

#root {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  /* overflow: hidden; */
  padding: 20px;
}

.wrapper {
  margin-top: 50px;
}

.container {
  padding: 20px;
  background-color: #161B22;
  border-radius: 10px;
  box-shadow: 0 8px 24px rgba(0,0,0,0.5);
  width: 100%;
  height: auto;
  max-height: 70vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none;
}

button {
  background-color: #238636;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  margin-right: 10px;
}

button:hover {
  background-color: #2EA043;
}

button:disabled {
  background-color: #30363d;
  cursor: not-allowed;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 8px 0;
  cursor: pointer;
}

input, select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  background-color: #0D1117;
  color: #C9D1D9;
  border: 1px solid #30363d;
  border-radius: 6px;
}

input:focus, select:focus {
  outline: none;
  border-color: #58A6FF;
}

label {
  font-weight: bold;
  display: block;
  margin-top: 20px;
}

.feedback-dialog {
  position: fixed;
  bottom: 30px;
  right: 20px;
  height: 500px;
  width: 300px;
  background-color: #0D1117;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}

.feedback-dialog input {
  width: 95%;
}

.feedback-dialog textarea {
  width: 95%;
  height: 325px;
  background-color: #0D1117;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px;
}

.close-btn {
  float: right;
  border: none;
  background: none;
  cursor: pointer;
}
